import React from 'react';
import Layout from '../components/layout';

function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
    <div className="blog-post-container">
    <div className="blog-post">
    <h1 className="text-white">{frontmatter.title}</h1>
    <h2 className="text-white">{frontmatter.date}</h2>
    <div
    className="blog-post-content"
    dangerouslySetInnerHTML={{ __html: html }}
    />
    </div>
    </div>
    </Layout>
    )
}

export default Template;

export const pageQuery = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      path
      title
    }
  }
}
`